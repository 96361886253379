import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Products from './pages/Products';
import Blog from './pages/Blog';

import NotFound from './pages/Page404';
import MainPage from "./pages/MainPage";
import DashboardLayout from "./layouts/dashboard";
import MainLayout from "./layouts/main";
import Orders from "./pages/Orders";
import * as CommunicationService from "./services/CommunicationService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AdminLogin from "./pages/AdminLogin";
import Register from "./pages/Register";
import OrderDetails from "./pages/OrderDetails";
import PaymentRedirect from "./pages/PaymentRedirect";
import Invoices from "./pages/Invoices";
import FeatureSwitches from "./pages/FeatureSwitches";
import Prices from "./pages/Prices";
import Defaults from "./pages/Defaults";
import Contact from "./pages/Contact";
import Drive from "./pages/Drive";
import PaymentLinks from "./pages/PaymentLinks";
import PaymentLinkComplete from "./pages/PaymentLinkComplete";
import Revenue from "./pages/Revenue";
import Banners from "./pages/Banners";
import CountryPrices from "./pages/CountryPrices";
import NormalPrices from "./pages/NormalPrices";
import Statistics from "./pages/Statistics";
import Blacklist from "./pages/Blacklist";
import MessageQueue from "./pages/MessageQueue";
import CommunicationLogs from "./pages/CommunicationLogs";
import TestingBench from "./pages/TestingBench";
import PaymentRedirectManual from "./pages/PaymentRedirectManual";
import MainPageWithParams from "./pages/special/MainPageWithParams";
import MainPageCluj from "./pages/special/MainPageCluj";
import * as LogicService from "./services/LogicService";
import * as Countries from "./constants/Countries";
import MainPageIasi from "./pages/special/MainPageIasi";
import MainPageTimisoara from "./pages/special/MainPageTimisoara";
import MainPageArad from "./pages/special/MainPageArad";
import MainPageBucuresti from "./pages/special/MainPageBucuresti";
import MainPageCraiova from "./pages/special/MainPageCraiova";
import MainPagePret from "./pages/special/MainPagePret";
import MainPageFirma from "./pages/special/MainPageFirma";
import MainPageRutier from "./pages/special/MainPageRutier";
import MainPageIntegritateComportamentala from "./pages/special/MainPageIntegritateComportamentala";
import MainPageListing from "./pages/special/MainPageListing";
import MainPageBrasov from "./pages/special/MainPageBrasov";
import MainPageOradea from "./pages/special/MainPageOradea";
import MainPageSibiu from "./pages/special/MainPageSibiu";
import MainPageConstanta from "./pages/special/MainPageConstanta";
import MainPageV2 from "./pages/special/MainPageV2";
import {useEffect, useState} from "react";
import EFactura from "./pages/EFactura";
import EmailSender from "./pages/EmailSender";
import MainPageIntegritateComportamentalaV2 from "./pages/special/MainPageIntegritateComportamentalaV2";
import MainPageV2_en from "./pages/special/MainPageV2_en";
import Fingerprint from "./pages/Fingerprint";
import MainPageRutierV2 from "./pages/special/MainPageRutierV2";

// ----------------------------------------------------------------------


function browserDataCheck() {
    let browserData = LogicService.getBrowserData();
    if (browserData === null) {
        try {
            fetch('https://www.cloudflare.com/cdn-cgi/trace')
                .then((res) => res.text().then((text) => {
                    let data = text.trim().split('\n').reduce(function (obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                    }, {});
                    let country = Countries.getCountryByCode(data.loc);
                    let json = {
                        ip: data.ip,
                        country: data.loc,
                        country_name: country.label,
                        country_code: data.loc,
                    }
                    LogicService.setBrowserData(json);
                }))
                .catch((error) => {
                    console.error(error);
                    let json = {
                        ip: "Could not get IP from cloudflare",
                        country: 'RO',
                        country_name: 'Romania',
                        country_code: 'RO',
                    }
                    LogicService.setBrowserData(json);
                })
        } catch (error) {
            console.error(error);
            let json = {
                ip: "Could not get IP from cloudflare",
                country: 'RO',
                country_name: 'Romania',
                country_code: 'RO',
            }
            LogicService.setBrowserData(json);
        }

    }
}

export default function Router() {

    // const [INTERFACE_V2_ENABLED, SET_INTERFACE_V2_ENABLED] = useState(true);
    //
    // LogicService.getFeatureSwitchCallback('INTERFACE_V2_ENABLED', (data, err) => {
    //     if (err) {
    //         return console.error(err);
    //     }
    //     if (data != null) {
    //         let interfaceFormPositionLeft = data.value;
    //         SET_INTERFACE_V2_ENABLED(interfaceFormPositionLeft);
    //     }
    // });

    function getMainPage() {
        return <MainPageV2 browserDataCheck={browserDataCheck}/>;
        // if (INTERFACE_V2_ENABLED == false) {
        //     return <MainPage/>
        // }
        // return <MainPageV2 browserDataCheck={browserDataCheck}/>;
    }

    let routes = [
        {
            path: '/',
            element: <MainLayout backgroundColor={'#f8f8f8'} paddingSides={'0'}/>,
            children: [
                // {element: <Navigate to="/" replace/>},
                {path: '/en', element: <MainPageV2_en browserDataCheck={browserDataCheck}/>},
                {path: '/en/order-offer', element: <MainPageV2_en browserDataCheck={browserDataCheck}/>},
                {path: '/en/complete-offer', element: <MainPageV2_en browserDataCheck={browserDataCheck}/>},

                {path: '/', element: getMainPage()},
                {path: 'order-offer', element: getMainPage()},
                {path: 'complete-offer', element: getMainPage()},

                {path: 'terms-and-conditions', element: <TermsAndConditions/>},
                {path: 'privacy-policy', element: <PrivacyPolicy/>},
                {path: 'contact', element: <Contact/>},
                {path: 'confirm-payment/:orderId/:orderCode', element: <PaymentRedirect/>},
                {path: 'manualp', element: <PaymentRedirectManual/>},
                {path: 'complete-order/:orderId/:shareCode', element: <PaymentLinkComplete/>},
                {path: 'detalii-comanda', element: <OrderDetails/>}

            ]
        },
        {
            path: '/',
            element: <MainLayout backgroundColor={'#f8f8f8'} paddingSides={'0'}/>,
            children: [
                // {element: <Navigate to="/" replace/>},
                {path: '/servicii', element: getMainPage()},
                {path: '/servicii/listing', element: <MainPageListing/>},
                {path: '/servicii/cazier-judiciar-online-cluj',                 element: <MainPageCluj browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-iasi',                 element: <MainPageIasi browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-timisoara',            element: <MainPageTimisoara browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-arad',                 element: <MainPageArad browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-brasov',               element: <MainPageBrasov browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-oradea',               element: <MainPageOradea browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-sibiu',                element: <MainPageSibiu browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-constanta',            element: <MainPageConstanta browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-bucuresti',            element: <MainPageBucuresti browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-craiova',              element: <MainPageCraiova browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-pret',                 element: <MainPagePret browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-judiciar-online-firma',                element: <MainPageFirma browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-online-rutier',                        element: <MainPageRutier browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/certificat-de-integritate-comportamentala-legacy',                element: <MainPageIntegritateComportamentala browserDataCheck={browserDataCheck}/>},

                {path: '/servicii/certificat-de-integritate-comportamentala',                    element: <MainPageIntegritateComportamentalaV2 browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/certificat-de-integritate-comportamentala/order-offer',        element: <MainPageIntegritateComportamentalaV2 browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/certificat-de-integritate-comportamentala/complete-offer',     element: <MainPageIntegritateComportamentalaV2 browserDataCheck={browserDataCheck}/>},

                {path: '/servicii/cazier-rutier-online',                    element: <MainPageRutierV2 browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-rutier-online/order-offer',        element: <MainPageRutierV2 browserDataCheck={browserDataCheck}/>},
                {path: '/servicii/cazier-rutier-online/complete-offer',     element: <MainPageRutierV2 browserDataCheck={browserDataCheck}/>},
            ]
        },
        // {
        //     path: '/cazier',
        //     element: <MainLayout/>,
        //     children: [
        //         {element: <Navigate to="/app" replace/>},
        //         {path: 'app', element: <MainPage/>},
        //         {path: 'order-offer', element: <MainPage/>},
        //         {path: 'confirm-payment/:orderId/:orderCode', element: <PaymentRedirect/>},
        //         {path: 'complete-offer', element: <MainPage/>},
        //         {path: 'detalii-comanda', element: <OrderDetails/>}
        //     ]
        // },
        {
            path: '/',
            element: <LogoOnlyLayout/>,
            children: [
                {path: 'verificare-comanda', element: <Login/>},
                {path: 'register', element: <Register/>},
                {path: 'admin-login', element: <AdminLogin/>},
                {path: '404', element: <NotFound/>},
                {path: '/', element: <Navigate to="/"/>},
                {path: '*', element: <Navigate to="/404"/>}
            ]
        },
        {path: '*', element: <Navigate to="/404" replace/>}
    ]

    let Auth = CommunicationService.getUser();
    let userType = CommunicationService.getUserType();

    let userIsLawyer = userType === 'ROLE_LAWYER';
    let userIsModerator = userType === 'ROLE_MODERATOR';
    let userIsAdmin = userType === 'ROLE_ADMIN';
    let hasAccess = Auth !== null && (userIsAdmin || userIsModerator || userIsLawyer)
    if (hasAccess) {
        let childs = [
            {path: 'orders', element: <Orders/>}
        ];
        if (userIsAdmin || userIsModerator) {
            // childs.push({path: 'user', element: <User/>});
            childs.push({path: 'invoices', element: <Invoices/>});
            childs.push({path: 'prices', element: <NormalPrices/>});
            childs.push({path: 'email-sender', element: <EmailSender/>});
            childs.push({path: 'country-prices', element: <CountryPrices/>});
            childs.push({path: 'feature-switches', element: <FeatureSwitches/>});
            childs.push({path: 'defaults', element: <Defaults/>});
            childs.push({path: 'drive', element: <Drive/>});
            childs.push({path: 'payment-links', element: <PaymentLinks/>});
            childs.push({path: 'companies', element: <Revenue/>});
            childs.push({path: 'statistics', element: <Statistics/>});
            childs.push({path: 'blacklist', element: <Blacklist/>});
            childs.push({path: 'message-queues', element: <MessageQueue/>});
            childs.push({path: 'banners', element: <Banners/>});
            childs.push({path: 'communication-logs', element: <CommunicationLogs/>});
            childs.push({path: 'fingerprints', element: <Fingerprint/>});
            childs.push({path: 'e-factura', element: <EFactura/>});
            childs.push({path: 'testing-bench', element: <TestingBench/>});
        }

        routes.push({
            path: '/admin',
            element: <DashboardLayout/>,
            children: [
                {element: <Navigate to="/admin/orders" replace/>},
                ...childs
            ]
        })
    }
    return useRoutes(routes);
}
