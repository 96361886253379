// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import {BaseOptionChartStyle} from './components/charts/BaseOptionChart';
import MainPageDashboardFooter from "./layouts/main/MainPageDashboardFooter";
import CookieIcon from '@mui/icons-material/Cookie';

import * as React from "react";
import {useState} from "react";
import {Alert, AlertTitle, Backdrop, Button, Collapse, IconButton, Snackbar, Typography} from "@mui/material";
import * as LogicService from "./services/LogicService";
import {setHideMethod} from "./services/LogicService";
import * as CommunicationService from "./services/CommunicationService";
import CircularProgress from "@mui/material/CircularProgress";
import { Toaster, toast } from 'sonner';


export default function App() {
    const [open, setOpen] = useState(false);
    // const [cookieOpen, setCookieOpen] = useState(LogicService.getAcceptedCookieValue());
    const [cookieOpen, setCookieOpen] = useState(true);


    const handleClick = () => {
        const waitInterval = setInterval(() => {
            if (!open) {
                setOpen(true);
                clearInterval(waitInterval);
            }
        }, 100);
    }

    // LogicService.setShowMethod(handleClick);

    LogicService.setShowMethod(toast);
    // LogicService.setHideMethod(() => setOpen(false));

    const handleClose = () => {
        setOpen(false);
    }

    const handleCookieClose = () => {
        LogicService.setAcceptedCookieValue(true);
        setCookieOpen(LogicService.getAcceptedCookieValue());
    }

    let Auth = CommunicationService.getUser();
    if (Auth !== undefined && Auth !== null && Auth.expireDate !== undefined && Auth.expireDate !== null) {
        let expireDate = new Date(JSON.parse(Auth.expireDate));
        let currentDate = new Date();
        if (expireDate < currentDate) {
            LogicService.resetSession();
            CommunicationService.setToken(null);
        }
    }

    return (
        <ThemeConfig>
            <ScrollToTop/>
            <GlobalStyles/>
            <BaseOptionChartStyle/>
            <Router/>

            {/*<Snackbar open={open}*/}
            {/*          anchorOrigin={{*/}
            {/*              vertical: 'top',*/}
            {/*              horizontal: 'center',*/}
            {/*          }}*/}
            {/*          // autoHideDuration={2000}*/}
            {/*          onClose={handleClose}>*/}
            {/*    <Alert onClose={handleClose} variant="filled"*/}
            {/*           severity={LogicService.getAlertSeverity()}*/}
            {/*           sx={{width: '100%'}}>*/}
            {/*        {LogicService.getAlertMessage()}*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}

            <Toaster/>



            <Snackbar open={!cookieOpen}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                      }}>
                <Alert variant="filled" severity="info"
                       action={
                           <Button color={"#FFF"} variant={'raised'} size="large" sx={{height: "100%"}} onClick={handleCookieClose}>
                               Sunt de acord
                           </Button>
                       }
                       iconMapping={{
                           info: <CookieIcon sx={{height: "100%"}}  fontSize="large" />,
                       }}
                >
                    <AlertTitle>Acest website folosește cookie-uri</AlertTitle>
                    <strong>Prin continuarea navigării, vă exprimați acordul asupra folosirii cookie-urilor.</strong>
                </Alert>
            </Snackbar>

            <footer className="footer">
                <div className="footoverlay"></div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading">Despre noi</h5>
                            <p className="footer-text">Dorim să facem procesul de obținere a cazierului judiciar mai
                                accesibil și mai eficient pentru toți clienții noștri. Cu experiență vastă în generarea
                                de caziere judiciare, echipa noastră de profesioniști se angajează să ofere soluții
                                rapide și sigure pentru nevoile tale legale. La caziere.ro, punem accent pe
                                confidențialitate, calitate și satisfacția clienților, oferindu-le acestora o experiență
                                simplă și plăcută în obținerea cazierului judiciar.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a href="https://caziere.ro/">Cazier judiciar online</a></h5>
                            <p className="footer-text">Ai nevoie de un cazier judiciar în mod rapid rapid și eficient?
                                Descoperă acum serviciile noastre online pentru obținerea cazierului judiciar cu doar
                                câteva clickuri. Oferim o soluție simplă și sigură pentru necesitățile tale legale.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-timisoara">Cazier judiciar
                                online Timişoara</a></h5>
                            <p className="footer-text">Locuitorii din Timişoara pot acum solicita cazierul judiciar
                                online într-un mod simplu și fără efort. Cu ajutorul platformei noastre online, ai acces
                                la informațiile tale judiciare în cel mai scurt timp posibil. Nu mai sta la cozi sau
                                pierde timpul cu proceduri tradiționale.</p>
                        </div>
                        <div className="col-md-3">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-cluj">Cazier judiciar online
                                Cluj</a></h5>
                            <p className="footer-text">Obține cazierul judiciar online în Cluj cu ușurință și eficiență.
                                Cu serviciile noastre, procesul devine simplu și rapid, economisindu-ți timp prețios. Ai
                                acces la datele tale judiciare într-un mod sigur și avantajos.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-craiova">Cazier judiciar online
                                Craiova</a></h5>
                            <p className="footer-text">Îți oferim posibilitatea de a obține cazierul judiciar online din
                                Craiova într-un mod rapid și fără bătăi de cap. Cu serviciile noastre online, ai acces
                                la informațiile tale judiciare în doar câteva clicuri. Simplu, convenabil și
                                confidențial.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-iasi">Cazier judiciar online
                                Iaşi</a></h5>
                            <p className="footer-text">Profită de posibilitatea de a obține cazierul judiciar online din
                                Iași în câteva minute. Cu doar câteva clickuri, ai acces la datele tale judiciare fără a
                                fi nevoie să te deplasezi la autorități. Simplu, rapid și sigur!</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-constanta">Cazier online
                                Constanța</a></h5>
                            <p className="footer-text">Acum ai posibilitatea de a obține cazierul judiciar online din
                                Constanța fără efort. Cu platforma noastră online, procesul devine rapid și convenabil,
                                economisindu-ți timp prețios. Nu mai sta la cozi sau pierde timpul cu proceduri
                                tradiționale.</p>
                        </div>
                        <div className="col-md-3">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-brasov">Cazier judiciar online
                                Braşov</a></h5>
                            <p className="footer-text">Îți punem la dispoziție servicii eficiente pentru obținerea
                                cazierului judiciar online din Brașov. Ai nevoie de câteva minute pentru a completa
                                cererea și vei primi documentul direct la tine acasă. Simplu, convenabil și în deplină
                                confidențialitate.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-sibiu">Cazier judiciar online
                                Sibiu</a></h5>
                            <p className="footer-text">Obține cazierul judiciar online în Sibiu fără efort și fără bătăi
                                de cap. Cu serviciile noastre online, procesul devine simplu și rapid, economisindu-ți
                                timp prețios. Ai acces la datele tale judiciare într-un mod sigur și convenabil.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-oradea">Cazier judiciar online
                                Oradea</a></h5>
                            <p className="footer-text">Poți solicita cazierul judiciar online din Oradea într-un mod
                                simplu și eficient. Cu platforma noastră online, ai acces la informațiile tale judiciare
                                în cel mai scurt timp posibil. Nu mai sta la cozi sau pierde timpul cu proceduri
                                tradiționale.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-arad">Cazier judiciar online
                                Arad</a></h5>
                            <p className="footer-text">Acum ai posibilitatea de a obține cazierul judiciar online din
                                Arad în cel mai scurt timp. Cu serviciile noastre, procesul devine rapid și convenabil,
                                economisindu-ți timp prețios. Simplu, convenabil și confidențial.</p>
                        </div>
                        <div className="col-md-3">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-firma">Cazier judiciar
                                firmă</a></h5>
                            <p className="footer-text">Ai nevoie de un cazier judiciar pentru firma ta? Oferim servicii
                                rapide și sigure pentru obținerea cazierului judiciar pentru compania ta. Simplifică
                                procesul și economisește timp cu platforma noastră online.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-bucuresti">Cazier online
                                Bucureşti</a></h5>
                            <p className="footer-text">Dacă ai nevoie de cazierul judiciar online din Brașov, acum îl
                                poți obține într-un mod rapid și convenabil. Cu serviciile noastre online, ai acces la
                                informațiile tale judiciare fără a fi nevoie să te deplasezi la autorități. Simplu,
                                rapid și sigur!</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/cazier-judiciar-online-pret">Cazier judiciar online
                                preţ</a></h5>
                            <p className="footer-text">Ai nevoie de informații despre prețul pentru obținerea cazierului
                                judiciar online? Află acum detalii despre tarifele noastre competitive și serviciile
                                incluse. Economisește timp și bani cu opțiunile noastre accesibile.</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <h5 className="footer-heading"><a href="https://caziere.ro/servicii/cazier-online-rutier">Cazier
                                rutier online</a></h5>
                            <p className="footer-text">Obține rapid și simplu cazierul rutier online cu platforma
                                noastră. Procesul este rapid și eficient, economisindu-ți timp prețios și asigurându-ți
                                că ești în conformitate cu reglementările legale.</p>
                        </div>
                        <div className="col-md-3">
                            <h5 className="footer-heading"><a
                                href="https://caziere.ro/servicii/certificat-de-integritate-comportamentala">Certificat
                                de integritate comportamentală online</a></h5>
                            <p className="footer-text">Ai nevoie de un certificat de integritate comportamentală rapid
                                și eficient? Descoperă acum serviciile noastre online pentru obținerea acestui document
                                esențial. Este o procedură rapidă și avantajoasă!</p>
                        </div>
                    </div>
                </div>
            </footer>

            <MainPageDashboardFooter id="footer"/>
        </ThemeConfig>
    );
}
