import Page from '../../components/Page';
import * as React from "react";
import {useEffect, useState} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-rutier-online-2.jpg"
import Foto_3 from "./images/cazier-rutier-online-3.jpg"
import Foto_4 from "./images/cazier-rutier-online-4.jpg"
import Foto_cta from "./images/cazier-rutier-online-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";
import * as AlertMapper from "../../services/AlertMapper";
import UnsupportedOrderTypeModal from "../../components/_dashboard/user/UnsupportedOrderTypeModal";
import {Button} from "@mui/material";

export default function MainPageRutier({browserDataCheck}) {

    const [reportProblemModalEnabled, setReportProblemModalEnabled] = useState(false);

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier auto online",
        "eliberare cazier auto online",
        "verificare cazier auto online",
        "obținere cazier rutier online",
        "cazier rutier online preț",
    ]

    return (
        <Page title={`Cazier Judiciar Online rutier`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Prin platforma noastră digitală, obținerea acestui document, respectiv cazierul rutier online, devine un proces simplu și accesibil de oriunde v-ați afla"}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier rutier online - Caziere.ro este un portal sigur pentru eliberarea rapidă a cazierului rutier online</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            <UnsupportedOrderTypeModal
                open={reportProblemModalEnabled}
                handleClose={() => {
                    setReportProblemModalEnabled(false);
                }}
                handleConfirm={() => {
                    setReportProblemModalEnabled(false);
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);
                }}
                url={'https://buy.stripe.com/aEU8xsc5I5R21lS3F12w'}
                title={"Introdu-ți detaliile mai jos"}
                content={"Pentru a elibera Cazierul Rutier avem nevoie de câteva detalii despre tine"}
                neededFiles={["Carte de identitate / Pașaport", "Permis de conducere"]}
            />

            <div class="section" style={{backgroundColor: '#f8f8f8'}}>
                <div class="container-fluid">
                    <div class="row align-items-center">

                        <div class="col-md-6 text-center padding-more">
                            <h1>Cazier rutier online</h1>
                            <p style={{color: '#00ab55', fontWeight: 'bold'}}>Caziere.ro este un portal sigur pentru
                                eliberarea rapidă a
                                cazierului rutier online.</p>

                            <p> Într-o lume în continuă mișcare, știm cât de prețios este timpul vostru și ne propunem
                                să vă oferim
                                servicii eficiente și facile pentru verificarea și obținerea cazierului auto, evitând
                                astfel pierderea
                                de timp valoros.</p>

                            <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                                    onClick={() => setReportProblemModalEnabled(true)}>
                                <i className="fas fa-download custom-icon"></i> Obțineți cazier auto
                            </Button>
                        </div>


                        <div class="col-md-6 bg-image" style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Obțineți repede și confortabil cazierul rutier online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => setReportProblemModalEnabled(true)}>
                        <i className="fas fa-download custom-icon"></i> Obțineți cazier auto
                    </Button>
                </div>
            </div>

            <div class="section" style={{backgroundColor: "#fbfbfb"}}>
                <div class="container">

                    <div class="row align-items-center">
                        <div class="col-md-6 order-md-2">
                            <h2>Situații în care aveți nevoie de un cazier rutier online</h2>
                            <p>Există numeroase momente în viața profesională sau personală când cazierul rutier online
                                devine necesar:
                                angajarea într-un domeniu ce presupune conducerea vehiculelor, închirierea unui
                                automobil sau
                                contestarea unor amenzile de trafic.</p>

                            <p>Pentru a demonstra responsabilitatea și experiența în conducere, un cazier rutier curat
                                este adesea
                                considerat un avantaj, fiind necesar și în procesul de obținere sau reînnoirea
                                permisului de conducere.
                                Mai mult, acest document poate fi solicitat de către angajatorii din străinătate,
                                evidențiind
                                necesitatea să în cazul relocării profesionale sau al desfășurării unor activități
                                comerciale
                                internaționale.</p>
                        </div>
                        <div class="col-md-6 order-md-1">
                            <h2>Ce este un cazier rutier obținut online</h2>
                            <p>Cazierul rutier obținut online este actul oficial care reflectă conduita dumneavoastră în
                                trafic. Fie că
                                este vorba despre abateri minore sau infracțiuni rutiere grave, acest document este
                                esențial pentru a
                                evidenția istoricul dumneavoastră auto. Prin platforma noastră digitală, obținerea
                                acestui document
                                devine un proces simplu și accesibil de oriunde v-ați afla.</p>

                            <p>Acesta reprezintă nu doar o modalitate eficientă de a acumula informații despre istoricul
                                de conducere al
                                unei persoane, ci și un instrument crucial pentru angajatori sau pentru alte entități
                                care necesită o
                                astfel de verificare. În plus, cazierul rutier online elimină necesitatea de a vă
                                deplasa la
                                autoritățile competente, transformând întregul proces într-o experiență digitală fără
                                bătăi de cap,
                                accesibilă cu doar câteva clicuri.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Obținere cazier auto online - ce ne recomandă ca fiind partenerul ideal pentru
                                dumneavoastră</h2>
                            <p>Apelând la serviciile noastre, vă asigurăm un proces simplificat și transparent, menit să
                                vă
                                economisească timpul și să vă elibereze de grija formalităților birocratice. Vă oferim
                                acces facil
                                la o platformă online securizată, suport și asistență constantă în completarea și
                                depunerea
                                documentației necesare, astfel încât întregul proces să fie cât mai eficient și lipsit
                                de stres
                                pentru dumneavoastră. </p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Echipa noastră lucrează cu eficiență maximă pentru ca
                                    cererile
                                    dumneavoastră să fie procesate în timp util, prevenind astfel orice întârziere.
                                </li>
                                <li dir="ltr" aria-level="1">Platforma noastră este optimizată pentru a oferi o
                                    experiență de
                                    utilizare intuitivă și sigură, protejând în același timp datele personale ale
                                    clienților noștri.
                                </li>
                                <li dir="ltr" aria-level="1">Prin utilizarea serviciilor noastre online, evitați
                                    deplasările la
                                    instituțiile de stat și petrecerea timpului în cozi nesfârșite.
                                </li>
                                <li dir="ltr" aria-level="1">Tratăm cu seriozitate securitatea informațiilor personale,
                                    implementând
                                    cele mai noi tehnologii de protecție a datelor și asigurând confidențialitatea
                                    totală a
                                    informațiilor dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Obțineți repede și confortabil cazierul rutier online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => setReportProblemModalEnabled(true)}>
                        <i className="fas fa-download custom-icon"></i> Obțineți cazier auto
                    </Button>
                </div>
            </div>


            <div class="section" style={{backgroundColor: "#fbfbfb"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Ce etape trebuie parcurse pentru eliberarea unui cazier auto online</h2>
                            <p>Pentru a vă ușura drumul către obținerea cazierului rutier online, am simplificat
                                procesul în pași ușor de
                                urmat:</p>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier rutier online" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Accesați platforma noastră online și completați formularul
                                    disponibil.
                                </li>
                                <li dir="ltr" aria-level="1">Încărcați documentele solicitate digital. Este necesar să
                                    furnizați o copie
                                    scanată a actului de identitate și a permisului de conducere. Procesul de încărcare
                                    este securizat,
                                    asigurând confidențialitatea datelor dvs.
                                </li>
                                <li dir="ltr" aria-level="1">Alegeți modalitatea de plată care vi se potrivește.
                                </li>
                                <li dir="ltr" aria-level="1">Urmați instrucțiunile primite pe email pentru finalizarea
                                    procesului.
                                </li>
                            </ul>
                            <p>Echipa noastră este pregătită să vă ofere suport în fiecare etapă a procesului. În cel
                                mai scurt timp, veți
                                intra în posesia cazierului rutier fără bătăi de cap, economisind timp și resurse ce ar
                                fi fost altfel
                                cheltuite pentru procesul tradițional de obținere.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Obțineți repede și confortabil cazierul rutier online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => setReportProblemModalEnabled(true)}>
                        <i className="fas fa-download custom-icon"></i> Obțineți cazier auto
                    </Button>
                </div>
            </div>


            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Acte necesare pentru obținerea unui cazier rutier online</h2>
                            <p>Pentru a vă facilita accesul la serviciile noastre și a vă asigura că procesul de
                                obținere a cazierului
                                rutier online decurge cât mai eficient posibil, este esențial să vă pregătiți
                                următoarele documente:</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier rutier online" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Copie după actul de identitate, care să fie clară și
                                    lizibilă, pentru a
                                    confirma identitatea solicitantului fără echivoc.
                                </li>
                                <li dir="ltr" aria-level="1">Permisul de conducere față/verso
                                </li>
                                <li dir="ltr" aria-level="1">Formularul de cerere completat cu atenție și semnat, fiind
                                    astfel cererea
                                    oficială pentru obținerea cazierului. Este important ca toate informațiile furnizate
                                    să fie corecte,
                                    pentru a evita întârzierile sau refuzul cererii.
                                </li>
                            </ul>
                            <p>Pe lângă aceste documente principale, e posibil să fie necesare și alte acte
                                suplimentare, în funcție de
                                specificul cererii sau de cerințele legale actuale. Este recomandat să verificați mereu
                                ultimele
                                actualizări legate de procesul de aplicare, pentru a vă asigura că aveți toate
                                documentele necesare
                                pregătite și valide. Pregătirea acestor documente asigură o procesare rapidă și
                                eficientă a cererii
                                voastre.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Obțineți repede și confortabil cazierul rutier online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => setReportProblemModalEnabled(true)}>
                        <i className="fas fa-download custom-icon"></i> Obțineți cazier auto
                    </Button>
                </div>
            </div>


            <div class="section">
                <div class="container">

                    <div class="row">
                        <div class="col-md-6 order-md-1">
                            <h2>Verificare cazier auto online</h2>
                            <p>Platforma noastră vă permite nu numai să obțineți cazierul rutier online, ci și să
                                verificați
                                situația actuală a istoricului vostru auto ori de câte ori este nevoie. Totul, cu câteva
                                click-uri,
                                din confortul propriului cămin sau de la birou.</p>

                            <p>Această facilitate este deosebit de utilă pentru șoferii care doresc să aibă o imagine
                                clară asupra
                                punctelor de penalizare sau a altor înregistrări ce pot afecta valabilitatea permisului
                                de
                                conducere. În plus, procesul simplificat de obținere sau verificare a cazierului auto
                                prin platforma
                                noastră vă economisește timp prețios, eliminând necesitatea deplasărilor fizice și
                                așteptării la
                                cozi lungi.</p>
                        </div>
                        <div class="col-md-6 order-md-2">
                            <h2>Cum se calculează prețul pentru un cazier rutier obținut online</h2>
                            <p>Prețul pentru obținerea cazierului rutier online este calculat în funcție de mai multe
                                criterii,
                                inclusiv dacă solicitarea vine din partea unei persoane fizice sau juridice, necesitatea
                                eliberării
                                în regim de urgență și dacă sunt necesare servicii suplimentare precum traducerea
                                legalizată sau
                                apostilarea.</p>

                            <p>Opțiunile de livrare, electronica sau prin curier, influențează și ele costul final. Vă
                                asigurăm că
                                oferim tarife competitive și transparente pentru toate serviciile noastre.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Obțineți repede și confortabil cazierul rutier online!</h2>
                    <a href={window.location.origin}>
                        <button><i class="fas fa-download custom-icon"></i> Obțineți cazier auto</button>
                    </a>
                </div>
            </div>

            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Vreți detalii despre obținerea unui cazier rutier online? Contactați-ne! </h2>
                            <p>Suntem aici pentru a răspunde la toate întrebările voastre privind procesul de obținere a
                                cazierului
                                rutier online. Nu ezitați să ne contactați la adresa de mail contact@caziere.ro pentru
                                orice
                                informații suplimentare sau clarificări. Echipa noastră de profesioniști este pregătită
                                să vă asiste
                                în demersurile voastre, asigurându-vă un serviciu de top, rapid și sigur.</p>

                            <p>Pe caziere.ro, garantăm un serviciu optimizat și accesibil pentru obținerea cazierului
                                rutier online,
                                indiferent de necesitățile voastre. Începeți acum demersul pentru obținerea cazierului
                                rutier și
                                bucurați-vă de libertatea și comoditatea oferită de serviciile noastre digitale!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
