import * as StorageService from "./StorageService";
import palette from "../theme/palette";

import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/WarningAmber';

export const DOCUMENTS_DETAILS = {
    ID_CARD: {
        title: 'Document de identitate (Buletin/Pașaport)',
        title_en: 'National ID/Passport',
        key: 'id_card',
        methodName: 'setIdCardUploaded',
        reUpdatable: true
    },
    DRIVE_DOC: {
        title: 'Permis de conducere',
        title_en: 'Driving license',
        key: 'drive_doc',
        methodName: 'setDriveDoc',
        reUpdatable: true
    },
    ID_CARD_BACK: {
        title: 'Spatele documentului de identitate (Buletin/Pașaport)',
        title_en: 'Back of identity document',
        key: 'id_card_back',
        methodName: 'setIdCardBackUploaded',
        reUpdatable: true,
        optional: true
    },
    PERSON_WITH_ID: {
        title: 'Dumneavoastră ținând documentul de identitate în mână',
        title_en: 'You holding your ID',
        key: 'person_with_id',
        methodName: 'setPersonWithIdCardUploaded',
        reUpdatable: true
    },
    BUSINESS_CONTRACT: {
        title: 'Împuternicire de la angajator',
        title_en: 'Power of attorney from employer',
        key: 'business_contract',
        methodName: 'setBusinessContractUploaded',
        reUpdatable: true
    },
    REGISTRATION_CERTIFICATE: {
        title: 'Certificatul de înregistrare a firmei',
        title_en: 'Certificate of company registration',
        key: 'registration_certificate',
        methodName: 'setRegistrationCertificate',
        reUpdatable: true
    },
    LAWYER_CONTRACT: {
        title: 'Contract',
        title_en: 'Contract',
        key: 'lawyer_contract',
        reUpdatable: true
    },
    POLICE_RECORD: {
        title: 'Cazier',
        title_en: 'Criminal record',
        key: 'police_record',
        reUpdatable: false
    },
    POLICE_RECORD_TRANSLATED: {
        title: 'Cazier tradus',
        title_en: 'Translated criminal record',
        key: 'police_record_translated',
        reUpdatable: false
    },
    SRL_ANEXA_38: {
        title: 'Anexa 38',
        title_en: 'Anexa 38',
        key: 'srl_anexa_38',
        reUpdatable: true
    },
    SIGNATURE_FOR_LAWYER_CONTRACT: {
        title: 'Împuternicire către avocat',
        title_en: 'Power of Attorney',
        key: 'signature_for_lawyer_contract',
        reUpdatable: false
    },
    PAYMENT_PROOF: {
        title: 'Dovada plății',
        title_en: 'Proof of Payment',
        key: 'payment_proof',
        reUpdatable: false
    },
    POWER_OF_ATTORNEY: {
        title: 'Împuternicire firmă',
        title_en: 'Company power of Attorney',
        key: 'power_of_attorney',
        reUpdatable: true
    },
    isPoliceRecord: (type) => {
        return (type === undefined || type === null) ? false : type.toLowerCase() === 'police_record'
    },
    isPoliceRecordTranslated: (type) => {
        return (type === undefined || type === null) ? false : type.toLowerCase() === 'police_record_translated'
    }
}

export const STATUSES = {
    P6_REGISTERED: {
        key: 'registered',
        title: 'Înregistrat',
        color: 'success',
        priority: 1
    },
    P3_PENDING_PAYMENT: {
        key: 'pending_payment',
        title: 'Așteptare plată',
        color: 'error',
        priority: 2
    },
    P5_ANALYZING: {
        key: 'analyzing',
        title: 'În analiză',
        color: 'warning',
        priority: 3
    },
    P1_IN_PROGRESS: {
        key: 'in_progress',
        title: 'În progress',
        color: 'info',
        priority: 4
    },
    P7_FINISHED: {
        key: 'finished',
        title: 'Finalizat',
        color: 'secondary',
        priority: 5
    },
    P2_COMPLETED: {
        key: 'completed',
        title: 'Completă',
        color: 'primary',
        priority: 6
    },
    P4_REFUNDED: {
        key: 'refunded',
        title: 'Refunded',
        color: 'error',
        priority: 5
    },
}

let messageSettled = "Ceva";
let severitySettled = "success";

let showMessage = () => {

}

let hideMessage = () => {
}

export function getAlertMessage() {
    return messageSettled;
}

export function getAlertSeverity() {
    return severitySettled;
}

export function setAlertMessageAndSeverity({message, severity}) {
    messageSettled = message;
    severitySettled = severity;
    hideMessage();
    let color = palette[severity].main

    let icons = {
        success: <SuccessIcon/>,
        error: <ErrorIcon/>,
        info: <WarningIcon/>,
    }

    showMessage(
        message, {
            duration: 3000,
            style: {
                background: color,
                color: 'white'
            },
            position: 'top-center',
            icon: icons[severity]
        });
}

export function setShowMethod(m) {
    showMessage = m;
}

export function setHideMethod(m) {
    hideMessage = m;
}

export function setOrder(id, price, transactionVerified, paymentCode,
                         necessaryDocuments, userType, details, shareable,
                         active, fiscalDone, nextState = 'PRICE') {
    let order = {
        id: id,
        price: price,
        transactionVerified: transactionVerified,
        necessaryDocuments: necessaryDocuments,
        paymentCode: paymentCode,
        userType: userType,
        details: details,
        shareable: shareable,
        active: active,
        fiscalDone: fiscalDone,
        nextState: nextState
    }

    StorageService.setOrder(order);
}

export function verifyTransactionAndReturn() {
    let order = getOrder();
    order.transactionVerified = true;
    order.nextState = "DOCUMENTS";
    StorageService.setOrder(order);
    return order;
}

export function updateOrderState(nextState) {
    let order = getOrder();
    order.nextState = nextState;
    StorageService.setOrder(order);
    return order;
}

export function verifyFiscalInfoAndReturn() {
    let order = getOrder();
    order.fiscalDone = true;
    order.nextState = 'PAYMENT_METHOD';
    StorageService.setOrder(order);
    return order;
}

export function undefineMyOrder() {
    StorageService.setOrder(null);
}

export function resetSession() {
    StorageService.setOrder(null);
    StorageService.setCreatedOrder(null);
    StorageService.setUploadedDocuments(null);
    StorageService.setPaymentMethod(null);
}

export function getOrder() {
    return StorageService.getOrder();
}

export function getUploadedDocuments() {
    let docs = StorageService.getUploadedDocuments();
    return docs === undefined || docs === null ? [] : docs;
}

export function addUploadedDocuments(doc) {
    let docs = getUploadedDocuments();
    if (docs !== undefined && docs !== null && docs.findIndex(d => d.key === doc.key) >= 0) {
        return;
    }
    docs.push(doc);
    StorageService.setUploadedDocuments(docs);
}

export function getPaymentMethod() {
    return StorageService.getPaymentMethod();
}

export function setPaymentMethod(paymentMethod) {
    return StorageService.setPaymentMethod(paymentMethod);
}

export function getBrowserData() {
    let data = StorageService.getBrowserData();
    return data === undefined || data === null ? null : data;
}

export function setBrowserData(doc) {
    StorageService.setBrowserData(doc);
}

export function getFeatureSwitches() {
    let data = StorageService.getFeatureSwitches();
    return data === undefined || data === null ? null : data;
}

export function getCompanyInUse() {
    let data = StorageService.getCompanyInUse();
    return data === undefined || data === null ? null : data;
}

export function getFeatureSwitch(key) {
    let data = StorageService.getFeatureSwitches();
    if (data === undefined || data === null) {
        return null;
    }
    return data.find(d => d.type == key.toUpperCase());
}

export function getFeatureSwitchCallback(key, callback) {
    let myInterval = setInterval(() => {
        let data = StorageService.getFeatureSwitches();
        if (data === undefined || data === null) {
            return;
        }
        clearInterval(myInterval);
        return callback(data.find(d => d.type == key.toUpperCase()));
    }, 500);
}

export function setCompanyInUse(doc) {
    StorageService.setCompanyInUse(doc);
}

export function setFeatureSwitches(doc) {
    StorageService.setFeatureSwitches(doc);
}

export function setPublicPrices(doc) {
    StorageService.setPublicPrices(doc);
}

export function getPublicPrices() {
    let data = StorageService.getPublicPrices();
    if (data === undefined || data === null) {
        return [];
    }
    return data;
}

let defaultPriceMap = {
    'DELIVERY_ELECTRONIC': 0,
    'REGIME_STANDARD': 0,
    'DELIVERY_IN_COUNTRY': 0,
    'APOSTILA': 200,
    'REGIME_FOREIGN_UE': 0,
    'LEGALIZED': 150,
    'REGIME_URGENT': 100,
    'BASIC_DOCUMENT': 250,
    'DELIVERY_OUT_OF_COUNTRY': 150,
    'BASIC_DOCUMENT_OUT_OF_COUNTRY': 450,
    'CERT_LEGALIZED': 100,
    'CERT_APOSTILA': 200,
    'CERT_EXPERT_VERIFICATION': 49,
    'CERT_DELIVERY_ELECTRONIC': 0,
    'CERT_REGIME_STANDARD': 179,
    'CERT_REGIME_URGENT': 219,
    'CERT_REGIME_STANDARD_OC': 339,
    'CERT_REGIME_URGENT_OC': 389
}

let defaultCountryPrices = [
    {
        "id": 25,
        "price": 100,
        "price_of": "ROMANA",
        "urgent_price": 100
    },
    {
        "id": 3,
        "price": 150,
        "price_of": "BULGARA",
        "urgent_price": 200
    },
    {
        "id": 4,
        "price": 150,
        "price_of": "CEHA",
        "urgent_price": 200
    },
    {
        "id": 5,
        "price": 300,
        "price_of": "CHINEZA",
        "urgent_price": 400
    },
    {
        "id": 6,
        "price": 300,
        "price_of": "COREEANA",
        "urgent_price": 400
    },
    {
        "id": 7,
        "price": 300,
        "price_of": "CROATA",
        "urgent_price": 400
    },
    {
        "id": 8,
        "price": 300,
        "price_of": "DANEZA",
        "urgent_price": 400
    },
    {
        "id": 10,
        "price": 150,
        "price_of": "ENGLEZA",
        "urgent_price": 200
    },
    {
        "id": 11,
        "price": 300,
        "price_of": "FINLANDEZA",
        "urgent_price": 400
    },
    {
        "id": 13,
        "price": 150,
        "price_of": "GERMANA",
        "urgent_price": 200
    },
    {
        "id": 14,
        "price": 150,
        "price_of": "GREACA",
        "urgent_price": 200
    },
    {
        "id": 15,
        "price": 150,
        "price_of": "ITALIANA",
        "urgent_price": 200
    },
    {
        "id": 16,
        "price": 300,
        "price_of": "JAPONEZA",
        "urgent_price": 400
    },
    {
        "id": 17,
        "price": 400,
        "price_of": "LITUANIANA",
        "urgent_price": 500
    },
    {
        "id": 18,
        "price": 400,
        "price_of": "MACEDONEANA",
        "urgent_price": 500
    },
    {
        "id": 20,
        "price": 250,
        "price_of": "NORVEGIANA",
        "urgent_price": 350
    },
    {
        "id": 21,
        "price": 150,
        "price_of": "OLANDEZA",
        "urgent_price": 200
    },
    {
        "id": 22,
        "price": 150,
        "price_of": "POLONEZA",
        "urgent_price": 200
    },
    {
        "id": 23,
        "price": 150,
        "price_of": "PORTUGHEZA",
        "urgent_price": 200
    },
    {
        "id": 24,
        "price": 200,
        "price_of": "RUSA",
        "urgent_price": 300
    },
    {
        "id": 26,
        "price": 300,
        "price_of": "SARBA",
        "urgent_price": 400
    },
    {
        "id": 27,
        "price": 300,
        "price_of": "SLOVACA",
        "urgent_price": 400
    },
    {
        "id": 28,
        "price": 300,
        "price_of": "SLOVENA",
        "urgent_price": 400
    },
    {
        "id": 29,
        "price": 150,
        "price_of": "SPANIOLA",
        "urgent_price": 200
    },
    {
        "id": 30,
        "price": 200,
        "price_of": "TURCA",
        "urgent_price": 300
    },
    {
        "id": 31,
        "price": 300,
        "price_of": "UCRAINEANA",
        "urgent_price": 400
    },
    {
        "id": 1,
        "price": 300,
        "price_of": "ALBANEZA",
        "urgent_price": 400
    },
    {
        "id": 2,
        "price": 300,
        "price_of": "ARABA",
        "urgent_price": 400
    },
    {
        "id": 19,
        "price": 150,
        "price_of": "MAGHIARA",
        "urgent_price": 200
    },
    {
        "id": 9,
        "price": 300,
        "price_of": "EBRAICA",
        "urgent_price": 400
    },
    {
        "id": 12,
        "price": 150,
        "price_of": "FRANCEZA",
        "urgent_price": 200
    }
]

export function getPublicPrice(key) {
    let find = getPublicPriceFUll(key);
    if (!find) {
        return defaultPriceMap[key];
    }
    return find.price;
}

function findByPriceOf(key) {
    return d => d.priceOf == key.toUpperCase();
}

export function getPublicPriceFUll(key) {
    let data = StorageService.getPublicPrices();
    if (data === undefined || data === null) {
        return null;
    }
    let find = data.find(findByPriceOf(key));
    if (!find) {
        find = defaultCountryPrices.find(findByPriceOf(key))
    }
    return find;
}

export function getCreatedOrder() {
    let data = StorageService.getCreatedOrder();
    if (data === undefined || data === null) {
        return null;
    }
    let currentDate = new Date();
    let differenceBetweenDatesInMinutes = Math.ceil(Math.abs(currentDate - new Date(data.retrievedAt)) / (1000 * 60));
    if (differenceBetweenDatesInMinutes > 15) {
        setCreatedOrder(null);
        return null;
    }
    return data;
}

export function setCreatedOrder(order) {
    if (order === undefined || order === null) {
        return StorageService.setCreatedOrder(null);
    }
    let orderPacked = {
        ...order,
        retrievedAt: new Date()
    }
    StorageService.setCreatedOrder(orderPacked);
}

export function getAcceptedCookieValue() {
    let obj = StorageService.getCookiesAcceptedValue();
    if (obj === undefined || obj === null) {
        return false;
    }
    return obj.value;
}

export function setAcceptedCookieValue(value) {
    StorageService.setCookiesAcceptedValue({value: value});
}


