// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Card, Stack, Typography} from '@mui/material';
// utils
import * as React from "react";
import {useState} from "react";
import {getPublicPrice, getPublicPriceFUll} from "../../../services/LogicService";
import * as LogicService from "../../../services/LogicService";

// ----------------------------------------------------------------------


export default function PoliceRecordPrice({regim, legalizedSelected, translationCountry, apostilaSelected, userIsForeign, travel, deliveryCountry}) {

    let browserData = LogicService.getBrowserData();

    let userOutside = true;
    if (browserData && browserData.country_code === 'RO') {
        userOutside = false;
    }
    let deliveryOutsideOfCountry = deliveryCountry === undefined ? false : deliveryCountry.code !== 'RO';
    if (deliveryOutsideOfCountry) {
        userOutside = true;
    }
    let isUrgent = regim === 'urgent';
    let isRegimElectronic = travel === 'electronic';

    let regimPrice = getPublicPrice(isUrgent ? 'REGIME_URGENT' : 'REGIME_STANDARD');


    let translationPrice = !translationCountry ? {urgentPrice: 0, price: 0} : getPublicPriceFUll(translationCountry.label)
    let finalTranslationPrice = 0;
    let finalApostilaPrice = 0;
    let finalExpertPrice = 0;
    let finalTravelPrice = getPublicPrice(isRegimElectronic ? 'DELIVERY_ELECTRONIC' :
        (deliveryOutsideOfCountry ? 'DELIVERY_OUT_OF_COUNTRY' : 'DELIVERY_IN_COUNTRY')
    );
    if (legalizedSelected == true) {
        finalTranslationPrice = isUrgent ? translationPrice.urgentPrice : translationPrice.price;
    }
    if (apostilaSelected == true) {
        finalApostilaPrice = getPublicPrice('APOSTILA');
    }
    if (userIsForeign == true) {
        finalExpertPrice = getPublicPrice('CERT_EXPERT_VERIFICATION');
    }
    let extraPrice = 0;
    if (userIsForeign && !isUrgent && apostilaSelected && (!isRegimElectronic && deliveryCountry.code != 'RO')) {
        extraPrice = 100;
    }

    return (
        <Stack direction="column"
               justifyContent="center"
               border={"1px solid rgba(0, 0, 0, 0.15)"}
               padding={"10px"} borderRadius={"10px"}
               sx={{
                   background: "rgba(0, 0, 0, 0.05)"
               }}
        >
            <Typography variant="h6" sx={{display: 'flex', justifyContent: 'flex-end'}}>
                Cost:
                {
                    getPublicPrice(userOutside ? 'BASIC_DOCUMENT_OUT_OF_COUNTRY' : 'BASIC_DOCUMENT')
                    + regimPrice + finalTranslationPrice + finalApostilaPrice + finalExpertPrice + finalTravelPrice + extraPrice
                } RON
            </Typography>
        </Stack>
    );
}
